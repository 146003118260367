import React, { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import "./sidebar.css";

// import {
//   useMediaQuery,
//   useStyles,
//   em,
//   IoIosArrowDroprightCircle,
//   IoIosArrowDropleftCircle,
// } from "@mfe/js-asulado-uiutils";
// import { fetchPermissions } from "@mfe/ts-asulado-utilitaries";

import {
  useMsal,
  useIsAuthenticated,
  MsalProvider,
  msalInstance,
} from "@mfe/react-asulado-msal";

enum UserRole {
  Administrador = 1,
  Auditor = 2,
  Consultor = 3,
}

const menuItems = [
  {
    name: "Búsqueda",
    path: "/app#busqueda",
  },
  {
    name: "Aprobación",
    path: "/app#aprobacion",
  },
  {
    name: "Detalles",
    path: "/app#detalles",
  },
];

export const SidebarComponent = () => {
  const instance = msalInstance;
  return (
    <MsalProvider instance={instance}>
      <Sidebar instance={instance} />
    </MsalProvider>
  );
};

const Sidebar = ({ instance }) => {
  const [currentStep, setCurrentStep] = useState(
    window.location.pathname + window.location.hash
  );

  // if (loading) {
  //   return (
  //     <LoadingOverlay
  //       visible={loading}
  //       overlayBlur={50}
  //       loaderProps={{ color: '#2EBADA' }}
  //     />
  //   );
  // }

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentStep(window.location.pathname + window.location.hash);
    };
    window.addEventListener("hashchange", handleHashChange); // Escucha cambios en el hash
    return () => {
      window.removeEventListener("hashchange", handleHashChange); // Limpia el listener al desmontar
    };
  }, []);

  const isActive = (path) => currentStep === path;

  return (
    <div className="sidebar-container">
      <ul className="step-list">
        {menuItems.map((row, index) => (
          <li
            key={index}
            className={`step-item 
              ${
                index + 1 <= menuItems.findIndex((s) => isActive(s.path)) &&
                "step-item-blue"
              }
            `}
            // onClick={() => navigateToUrl(row.path)}
          >
            <div
              className={`step-circle 
                ${
                  index <= menuItems.findIndex((s) => isActive(s.path)) &&
                  "step-circle-blue"
                }
            `}
            >
              {index + 1}
            </div>
            <span className="step-label">{row.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
